import React from "react"
import SbEditable from "storyblok-react"
import { Flex, Box, Heading } from "theme-ui"
import Richtext from "./richtext.js"
import Coverimage from "./cover_image"
import Cta from "./cta"
import Section from "./section"
import Subheading from "./preheading"

const Gallery = props => {
  return (
    <SbEditable content={props.blok}>
      <Flex sx={{ flexDirection: "column" }}>
        <Coverimage
          image={props.blok.image_1}
          sx={{
            width: "100%",
            height: ["30vh", "auto", "auto", "70vh"],
          }}
        />

        <Section
          sx={{
            overflow: "visible",
            "& > div": {
              display: "flex",
              overflow: "visible",
              flexDirection: ["column", "column", "row"],
            },
          }}
        >
          <Flex
            sx={{
              width: ["100%", "100%", "50%", "50%", "60%"],
              mt: [null, null, -5],
              order: ["3", "3", "2"],
              boxShadow: [null, null, "card"],
              //maxHeight: "100vh",
            }}
          >
            <Coverimage
              image={props.blok.image_2}
              srcsetSizes="(min-width: 1600px) 60vw, (min-width: 992px) 80vw, (min-width: 576px) 100vw, (max-width: 575px) 100vw, 100vw"
              factor={1}
              //overflow="hidden"
              sx={{
                lineHeight: "0",
                zIndex: "10",
                p: 0,
                mx: [-4, -4, 0],
                width: ["calc(100% + 64px)", null, "100%", "100%"],
                height: "100%",
              }}
            />
          </Flex>
          <Flex
            sx={{
              width: ["100%", "100%", "50%"],
              order: ["2", "2", "3"],
            }}
          >
            <Box
              sx={{
                py: [4, 4, 5, null, 5],
                pl: [4, 4, 5, null, null, "6rem"],
                pr: [4, 4, 0],
                my: [-4, -4, 0],
                bg: ["#fff", "#fff", "transparent"],
                boxShadow: ["card", null, "none"],
                zIndex: "99",
              }}
            >
              {props.blok.pre_title && (
                <Subheading>{props.blok.pre_title}</Subheading>
              )}
              <Heading as="h2" variant="underlined" sx={{ maxWidth: "12ch" }}>
                {props.blok.title}
              </Heading>
              {props.blok.description && (
                <Box
                  my="4"
                  sx={{
                    "& > div > p": {
                      fontSize: [3, null, null, null, null, null, 4],
                    },
                  }}
                >
                  <Richtext text={props.blok.description} />
                </Box>
              )}
              {props.blok.cta &&
                props.blok.cta.map(blok => (
                  <Cta key={blok._uid} blok={blok} button_type="arrowLink" />
                ))}
            </Box>
          </Flex>
        </Section>
      </Flex>
    </SbEditable>
  )
}
export default Gallery
