import React from "react"
import Components from "../components"
import { Text, Flex } from "theme-ui"
import { Hero } from "../hero"
import { useDate } from "../../utils/date_hook"
import LabelValue from "../label_value"
import TitleText from "../title_text"
import IntroDetails from "../intro_details"
import VideoIframe from "../video_iframe"
import ImageTextGallery from "../image_text_gallery"
import LocationIntro from "../location_intro"
import RelatedContentCards from "../related_content_cards"
import { CardSlider } from "../slider"
import { useTranslations } from "../../utils/label_translations_hook"
//import LargeCtaBanner from "../large_cta_banner"
import FormEmbed from "../form_embed"

const Event = props => {
  const programLabel = useTranslations("program")
  const introTitle = useTranslations("details")
  const servicesTitle = useTranslations("available_services")
  const eventTypes = useTranslations("other_ways_to_experience")

  const offerIntro = (
    <Flex sx={{ flexDirection: "column", mb: 3 }}>
      {props.blok.offer_base_price && (
        <Text sx={{ fontWeight: "600", fontSize: 5, lineHeight: 1.1 }}>
          {props.blok.offer_base_price}
        </Text>
      )}
      {props.blok.offer_base_price_comment && (
        <Text sx={{ color: "textGrey", fontSize: 2 }}>
          {props.blok.offer_base_price_comment}
        </Text>
      )}
    </Flex>
  )

  let startDate = useDate(props.blok.sort_by_date, true)
  let endDate = useDate(props.blok.date_end, true)

  endDate = endDate !== startDate ? endDate : null
  //startDate = endDate ? startDate + " - " : startDate

  return (
    <main>
      <Hero
        image={props.blok.main_image}
        title={props.blok.title}
        variant="herohome"
      />

      <IntroDetails title={introTitle}>
        <LabelValue labelText="date">
          {startDate}
          {endDate && "-"}
          {endDate}
        </LabelValue>
        {props.location && props.location.content && (
          <LabelValue labelText="location">
            {props.location.content.title}
          </LabelValue>
        )}
        {props.blok.event_type && props.blok.event_type.story && (
          <LabelValue labelText="type">
            {props.blok.event_type.story.name}
          </LabelValue>
        )}
        {props.blok.level && (
          <LabelValue labelText="level">{props.blok.level}</LabelValue>
        )}
        {props.blok.partner && (
          <LabelValue labelText="partner">{props.blok.partner}</LabelValue>
        )}
      </IntroDetails>
      {props.blok.introduction && props.blok.introduction[0] && (
        <TitleText blok={props.blok.introduction[0]} />
      )}

      {props.blok.video_id && (
        <VideoIframe blok={{ video_id: props.blok.video_id }} />
      )}

      {props.blok.program_blok &&
        props.blok.program_blok[0] &&
        props.blok.program_blok.map(blok => (
          <ImageTextGallery blok={blok} key={blok._uid} />
        ))}

      {props.blok.program_image_1 &&
        props.blok.program_image_1.filename &&
        props.blok.program_image_2 &&
        props.blok.program_image_2.filename && (
          <ImageTextGallery
            blok={{
              image_1: props.blok.program_image_1,
              image_2: props.blok.program_image_2,
              pre_title: programLabel,
              title: props.blok.program_title,
              description: props.blok.program_short_description,
              cta: props.blok.program_cta,
            }}
          />
        )}

      {props.blok.location_link && (
        <LocationIntro location={props.blok.location_link} />
      )}

      {props.blok.location_content &&
        props.blok.location_content.map(blok =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
          })
        )}

      {props.blok.long_description &&
        props.blok.long_description.map(blok => (
          <TitleText key={blok._uid} blok={blok} />
        ))}

      {props.blok.offer_title && (
        <TitleText
          blok={{
            title: props.blok.offer_title,
            intro: offerIntro,
            text: props.blok.offer_text,
          }}
          sx={{
            "& ul": { m: 0, paddingInlineStart: "20px" },
          }}
        />
      )}

      {props.blok.options &&
        props.blok.options.map(blok => (
          <TitleText
            key={blok._uid}
            blok={blok}
            sx={{
              "& ul": {
                paddingInlineStart: "20px",
                m: 0,
              },
            }}
          />
        ))}

      {props.blok.services && props.blok.services.length > 0 && (
        <RelatedContentCards
          loop={props.blok.services}
          title={servicesTitle}
          // mb={[0, 0, 0, 0]}
          // pb={[4, null, 5, 5]}
        />
      )}

      {props.blok.contact &&
        props.blok.contact.map(blok => <FormEmbed blok={blok} />)}

      {/* {props.blok.contact_cta &&
        props.blok.contact_cta.bloks &&
        props.blok.contact_cta.bloks.map(blok => (
          <LargeCtaBanner key={blok._uid} blok={blok} />
        ))} */}

      {props.blok.loop && props.blok.overview && (
        <CardSlider
          loop={props.blok.loop}
          title={eventTypes}
          link={{ linktype: "story", full_slug: props.blok.overview }}
          slider_type="event_types"
        />
      )}
    </main>
  )
}

export default Event
