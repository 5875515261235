import React from "react"
import { graphql } from "gatsby"
//import Components from "../components/components_stories"
import Event from "../components/routes/event"
import Header from "../components/header"
import Seo from "../components/seo"
import Footer from "../components/footer"
import { menuColor } from "./menu_color"
import { Flex } from "theme-ui"
import { LabelsProvider } from "../utils/labels_context"

class StoryblokEntry extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (state.story.uuid === props.pageContext.story.uuid) {
      return null
    }

    return StoryblokEntry.prepareStory(props)
  }

  static prepareStory(props) {
    const story = Object.assign({}, props.pageContext.story)
    const globalNavi = Object.assign({}, props.pageContext.globalNavi)
    const footer = Object.assign({}, props.pageContext.footer)
    const labelTranslations = Object.assign(
      {},
      props.pageContext.labelTranslations
    )
    story.content = JSON.parse(story.content)
    globalNavi.content = JSON.parse(globalNavi.content)
    footer.content = JSON.parse(footer.content)
    labelTranslations.content = JSON.parse(labelTranslations.content)

    return { story, globalNavi, footer, labelTranslations }
  }

  constructor(props) {
    super(props)

    this.state = StoryblokEntry.prepareStory(props)
  }

  render() {
    let content = this.state.story.content
    let globalNavi = this.state.globalNavi.content
    let footer = this.state.footer.content
    let langSwitch = this.state.story.translated_slugs
    content.sort_by_date = this.state.story.sort_by_date
    content.lang =
      content.seo.lang =
      globalNavi.lang =
      langSwitch.currentLang =
        this.state.story.lang
    content.loop = this.props.data.loop.edges
    content.overview = this.props.data.overview.full_slug

    return (
      <Flex sx={{ minHeight: "100vh", flexDirection: "column" }}>
        <LabelsProvider
          value={{
            lang: this.state.story.lang,
            labels: this.state.labelTranslations.content,
          }}
        >
          <Header
            blok={globalNavi}
            langs={langSwitch}
            menuColor={menuColor(content)}
          ></Header>
          <Event key={content._uid} blok={content} />
          <Footer blok={footer} langs={langSwitch} />
        </LabelsProvider>
      </Flex>
    )
  }
}

export default StoryblokEntry

export const Head = props => (
  <Seo meta={props.pageContext.story} lang={props.pageContext.lang} />
)

export const pageQuery = graphql`
  query (
    $lang: String!
    $category: String!
    $related_content_1: String
    $related_content_2: String
  ) {
    loop: allStoryblokEntry(
      filter: { field_component: { eq: $category }, lang: { eq: $lang } }
    ) {
      edges {
        node {
          id
          name
          lang
          content
          full_slug
        }
      }
    }
    overview: storyblokEntry(
      field_component: { eq: "event_overview" }
      lang: { eq: $lang }
    ) {
      full_slug
    }
    related_content_1: storyblokEntry(
      uuid: { eq: $related_content_1 }
      lang: { eq: $lang }
    ) {
      id
      uuid
      name
      lang
      content
      full_slug
    }
    related_content_2: storyblokEntry(
      uuid: { eq: $related_content_2 }
      lang: { eq: $lang }
    ) {
      id
      uuid
      name
      lang
      content
      full_slug
    }
  }
`
