import React from "react"
import { Box, Grid, Flex, Link, Button, Heading } from "theme-ui"
import Section from "./section"
import Picture from "./picture"
import Label from "./label"

const LocationIntro = ({ location }) => (
  <Section mb={["5", null, "6"]}>
    {location.content && (
      <Grid
        columns={[1, null, null, 2]}
        gap={[0, null, null, 4]}
        sx={{
          gridTemplateAreas: [
            "'image' 'text'",
            null,
            null,
            "'title .''text image'",
          ],
        }}
      >
        <Box sx={{ gridArea: "text" }}>
          <Label variant="smallTitle">location</Label>
          <Heading as="h3" variant="underlined">
            {location.content.title && location.content.title}
          </Heading>
          <Box sx={{ mt: [3] }}>
            {location.content.short_description &&
              location.content.short_description}
          </Box>
          {location.full_slug && (
            <Link href={"/" + location.full_slug}>
              <Button variant="outline" sx={{ mt: 4 }}>
                <Label>more_about_this_location</Label>
              </Button>
            </Link>
          )}
        </Box>
        <Box sx={{ gridArea: "image", mb: [3, null, 4, 0] }}>
          <Flex sx={{ height: "100%", alignItems: "center" }}>
            {location.content.track_layout && (
              <Picture
                image={location.content.track_layout}
                sx={{ display: "blok" }}
              />
            )}
          </Flex>
        </Box>
      </Grid>
    )}
  </Section>
)

export default LocationIntro
